@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-style: normal;
  font-weight: normal;
}
.body-kg {
  font-family: 'DM Mono', monospace;
  font-size: 16px;
  font-weight: normal;
  line-height: 1.5;
  min-height: 100vh;
  background: #fff;
  color: var(--shark);
}

.header-menu-kg {
  box-shadow: 4px 4px 0px 0px black;
}

.smooth-image {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.75) 0%, rgba(255, 255, 255, 0.75) 100%),
    url('/bg.webp') no-repeat center / cover;
}

.pure-shadow {
  box-shadow: 0.5rem 0.5rem var(--gray-900);
}